import "../../assets/css/dashboard.css";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import CustormerImg from "../../assets/images/icons/customer.svg";

import { ReactComponent as CameraIcon } from "../../assets/images/icons/camera.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/icons/log-out.svg";

import NumberFormat from "react-number-format";

import EmptyGif from "../../assets/images/illustrations/empty.gif";

import FolderIcon from "../../assets/images/illustrations/12_folder.png";
import FooterDisplay from "../../components/footer";

import { fetchData, profileData } from "../../slices/profileSlice";
import AllAppRoutes from "../../common/routes";
import {
  _confirm_user_transfer_pin,
  _generate_otp,
  _get_profile,
  _update_profile,
  _update_user_type,
  _user_dashboard_referrals,
  _verify_otp,
} from "../../common/axios_services";
import { useDispatch } from "react-redux";
import { Drawer, Spin, Switch, Upload, notification } from "antd";
import RequestTransactionCode from "../../components/profile/transcode";
import AppRoutes from "../../common/routes";

import { Input, Select, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ProfileSettings = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [jwt] = useState(localStorage.getItem("konnect_token"));

  const newAntIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      description: message,
    });
  };

  const [referralSummary, setReferralSummary] = useState({});
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [userData, setUserData] = useState({});
  const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
  const [userPin, setUserPin] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [transCodeDrawer, setTransCodeDrawer] = useState(false);
  const [otpCounter, setOtpCounter] = useState(false);
  const [userType, setUserType] = useState("basic");
  const [imageUploadError, setImageUploadError] = useState(false);
  const [displaySalesAgentMessage, setDisplaySalesAgentMessage] =
    useState(false);

  const [imageUrl, setImageUrl] = useState("");
  const [imageUpload, setImageUpload] = useState({});
  const [loading, setLoading] = useState(false);
  const [displayPageLoader, setDisplayPageLoader] = useState(false);
  const [displayUpdateProfilePicModal, setdisplayUpdateProfilePicModal] =
    useState(false);

  const toggleTransactionCodeDrawer = () => {
    setTransCodeDrawer(!transCodeDrawer);
  };
  const toggleTransCodeModalDisplay = () => {
    setDisplayTransCodeModal(!displayTransCodeModal);
    setOtpCounter(true);
  };
  const toggleOTPCounter = () => setOtpCounter(!otpCounter);

  const getReferralSummary = async () => {
    try {
      // const referrals = await _get_all_referrals({ page, page_size })
      const referrals = await _user_dashboard_referrals();
      if (referrals.data.status === "success") {
        setLoadingSpinner(false);
        setReferralSummary(referrals.data.data);
      } else {
        openNotificationWithIcon("error", referrals.data.message);
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          Navigate("/");
        }
        openNotificationWithIcon("error", err.response.data.message);
      } else {
        openNotificationWithIcon("error", err.message);
      }
      // openNotificationWithIcon('error', 'An error occurred while loading referral history');
    }
  };
  const fetchUser = async () => {
    try {
      const user_profile = await _get_profile();
      dispatch(fetchData(true));
      dispatch(profileData(user_profile?.data?.data));
      let userData = user_profile?.data.data;
      setUserData(userData);
      setUserType(userData.is_ra ? "ra" : "basic");
      setLoadingSpinner(false);
      console.log(userData);
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          Navigate(`/signin`);
        }
        dispatch(profileData(false));
        dispatch(fetchData(false));
      } else {
      }
      setLoadingSpinner(false);
    }
  };
  useEffect(() => {
    if (jwt) {
      fetchUser();
      // getReferralSummary();
    } else {
      setLoadingSpinner(false);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("konnect_token");
    Navigate("/signin");
  };

  const sendOTPToUser = async () => {
    setLoadingSpinner(true);
    try {
      const generate_otp = await _generate_otp();
      openNotificationWithIcon("success", generate_otp?.data?.message);
      setLoadingSpinner(false);
      toggleTransCodeModalDisplay();
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          openNotificationWithIcon("error", err?.response?.data?.message);
          Navigate("/signin");
        }
        openNotificationWithIcon("error", err?.response?.data?.message);
      } else {
        openNotificationWithIcon("error", err.message);
      }
      setLoadingSpinner(false);
    }
  };

  const VerifyOtp = async () => {
    setLoadingSpinner(true);
    try {
      const verifyOtp = await _verify_otp({ otp: userPin });
      setLoadingSpinner(false);
      openNotificationWithIcon("success", verifyOtp?.data?.message);
      toggleTransactionCodeDrawer();
    } catch (err) {
      setLoadingSpinner(false);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          openNotificationWithIcon(
            err?.response?.data?.status,
            err?.response?.data?.message
          );
          Navigate("/signin");
        } else {
          openNotificationWithIcon(
            err?.response?.data?.status,
            err?.response?.data?.message
          );
        }
      } else {
        openNotificationWithIcon("error", err.message);
      }
    }
  };

  const verifyUserPin = async (e) => {
    setLoadingSpinner(true);
    toggleTransCodeModalDisplay();
    setDisplayTransCodeModal(false);
    try {
      const profile = await _confirm_user_transfer_pin(e);
      toggleTransactionCodeDrawer();
      setLoadingSpinner(false);
    } catch (err) {
      if (err?.response?.data?.message) {
        if (err?.code === "ERR_NETWORK") {
          openNotificationWithIcon(
            "error",
            "An error occurred while checking pin. Please check network and try again"
          );
        } else if (err?.response?.data?.message === "Unauthorized") {
          openNotificationWithIcon(
            "error",
            "User Authorization expired. Please log in to continue"
          );
          Navigate("/signin");
        } else {
          openNotificationWithIcon(
            "error",
            err?.data?.message.length
              ? err?.data?.message
              : err?.response?.data?.message
          );
        }
      } else {
        openNotificationWithIcon(
          "error",
          "An error occurred while checking pin. Please try again"
        );
      }
      setLoadingSpinner(false);
    }
  };

  const updateUserType = async () => {
    setLoadingSpinner(true);
    try {
      const changeType = await _update_user_type({ status: !userData.is_ra });
      setLoadingSpinner(false);
      openNotificationWithIcon("success", changeType?.data?.message);
      let currType = userData.is_ra;
      if (currType) {
        setUserType("basic");
      } else {
        setUserType("ra");
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          openNotificationWithIcon(
            err?.response?.data?.status,
            err?.response?.data?.message
          );
          Navigate("/signin");
        } else {
          openNotificationWithIcon(
            err?.response?.data?.status,
            err?.response?.data?.message
          );
        }
      } else {
        openNotificationWithIcon("error", err.message);
      }
    }
    setLoadingSpinner(false);
  };

  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  //   const handleChange = (info) => {
  //     getBase64(
  //       info.file.originFileObj,
  //       (imageUrl) => setImageUrl(imageUrl),
  //       setLoading(false)
  //     );
  //     const reader = new FileReader();

  //     reader.readAsDataURL(info.file.originFileObj);
  //     reader.addEventListener("load", () => {
  //       setImageToSend(reader.result);
  //     });
  //   };

  const toggleSalesAgentMessage = () => {
    setDisplaySalesAgentMessage(!displaySalesAgentMessage);
  };

  const beforeUpload = (file) => {
    setImageUploadError(false);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setImageUploadError(true);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      setImageUploadError(true);
      message.error("Image must smaller than 5MB!");
    }
    // return isJpgOrPng && isLt2M;
    return false;
  };

  const getBase64 = (img) => {
    const file = img;
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      setImageUrl(reader.result);
    });

    if (!imageUploadError) {
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleChange = (info) => {
    getBase64(info.file);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const UpdateProfilePicFunct = async () => {
    if (imageUrl) {
      setLoadingSpinner(true);
      setdisplayUpdateProfilePicModal(false);
      try {
        const data = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone_number: userData.phone_number,
          //   userType: userData.userType,
          gender: userData.gender,
          dob: userData.dob,
          image: imageUrl,
          pin: userData.pin,
          age: userData.age,
        };
        const updateProfile = await _update_profile(data);
        setLoadingSpinner(false);
        Navigate(0);
        openNotificationWithIcon("success", "Profile updated successfully");
      } catch (err) {
        if (err.response) {
          if (err.response.data.message === "Unauthorized") {
            localStorage.removeItem("konnect_token");
            Navigate("/signin");
            // setProfileErr(false)
            openNotificationWithIcon("error", err.message);
          } else {
            // setProfileErr(err.response.data.message)
            openNotificationWithIcon("error", err.response.data.message);
          }
        } else {
          openNotificationWithIcon("error", err.message);
        }
        setLoadingSpinner(false);
      }
    } else {
      openNotificationWithIcon("error", "Please select an image");
    }
  };

  return (
    <Spin spinning={loadingSpinner} indicator={newAntIcon}>
      <div className="profile-display settings-display">
        <div className="settings-detail-summary">
          {jwt ? (
            <>
              {/* <div className="small-page-title contain">
                                    <div className="grid-flex">
                                        <div
                                            onClick={() => Navigate(-1)}>
                                            <ion-icon name="arrow-back-circle"></ion-icon>
                                        </div>
                                    </div>
                                </div> */}
              <div className="profile-first-sect">
                <div className="top-body-box">
                  <div className="grid-flex">
                    <div className="chat">
                      <a
                        href="https://tawk.to/chat/61590f9625797d7a8901fd89/default"
                        target="_blank"
                      >
                        <img
                          src={CustormerImg}
                          className="customer"
                          alt="customer"
                        />
                        {/* <ion-icon name="chatbubbles-outline"></ion-icon> */}
                      </a>
                    </div>
                    <div onClick={logout} className="logout">
                      <LogoutIcon />
                    </div>
                  </div>
                </div>
                <div className="contain">
                  {/* <Upload {...props}> */}
                  <div className="avatar-blk-cover">
                    {!userData?.image?.length ? (
                      <CameraIcon
                        onClick={() => setdisplayUpdateProfilePicModal(true)}
                      />
                    ) : (
                      <img
                        onClick={() => setdisplayUpdateProfilePicModal(true)}
                        src={userData.image}
                        alt={`${userData.first_name} profile picture`}
                      />
                    )}
                  </div>
                  {/* </Upload> */}
                  <div>
                    <h4>
                      {userData.first_name} {userData.last_name}
                    </h4>
                    <div>
                      <button
                        onClick={toggleTransCodeModalDisplay}
                        className="btn-blank"
                        to=""
                      >
                        View PIN
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-list-display ">
                <div className="settings-list">
                  <div className="settings-inner-list">
                    <div>
                      <ul>
                        <li>
                          <Link to={AppRoutes.basic_profile}>
                            <p>Update Basic Information</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                        <li>
                          <Link to={AppRoutes.update_password}>
                            <p>Update Password</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                        <li>
                          <Link to={AppRoutes.update_transaction_code}>
                            <p>Update Transaction PIN</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                        {userType === "basic" ? (
                          <li>
                            <Link to="#">
                              <p>Join Us</p>
                              {/* <ion-icon name="chevron-forward-outline"></ion-icon> */}
                              <div>
                                <Switch
                                  onChange={updateUserType}
                                  defaultChecked={
                                    userType === "ra" ? true : false
                                  }
                                />
                                {/* <button onClick={updateUserType} className="btn-base">Click Here</button> */}
                              </div>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}
                        <li>
                          <Link to={AppRoutes.profile_referral}>
                            <p>Referral Information</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                        {/* {userType === "ra" ? ( */}
                        {userData.is_sa ? (
                          <li>
                            <Link to={AppRoutes.e_id}>
                              <p>e-ID</p>
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}
                        {userData.is_sa ? (
                          <li>
                            <Link to="/profile/sales-report">
                              <p>View Sales Report</p>
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="settings-inner-list">
                    <div>
                      <ul>
                      {userData.is_sa ? (
                          <li>
                            <Link to={AppRoutes.partner}>
                              <p>Join The Team</p>
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}
                        <li>
                          <Link to="#">
                            <p>Contact Us</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                        <li>
                          <Link to={AppRoutes.terms}>
                            <p>Terms of Use</p>
                            {/* <ion-icon name="chevron-forward-outline"></ion-icon> */}
                          </Link>
                        </li>
                        <li>
                          <Link to={AppRoutes.privacy}>
                            <p>Privacy Policies</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                        <li>
                          <Link
                            style={{ color: "#A9411D" }}
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              logout();
                            }}
                          >
                            <p>Sign Out</p>
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: 40,
                  fontSize: "1.3rem",
                }}
              >
                <p>
                  User since{" "}
                  {DateTime.fromISO(userData.created_at).toLocaleString(
                    DateTime.DATE_HUGE
                  )}
                </p>
              </div>
            </>
          ) : (
            <div className="center-content short mt_5">
              <div className="center-content-text">
                <img src={FolderIcon} alt="folder" />
                <h4>Create a Konnect Account</h4>
                <p>
                  Konnect allows you to enjoy instant and monthly cash reward
                  from product offers and commision when you refer friends.
                  Sign-up now to get started.
                </p>
                <div>
                  <div className="">
                    <Link className="btn-base" to={AllAppRoutes.signup}>
                      Create account now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <RequestTransactionCode
          openDrawer={displayTransCodeModal}
          closeDrawer={toggleTransCodeModalDisplay}
          inputCount={4}
          actionFunction={verifyUserPin}
          stateFunc={setUserPin}
        />

        <Drawer
          footer={null}
          onClose={toggleTransactionCodeDrawer}
          open={transCodeDrawer}
          placement="bottom"
        >
          <div className="modal-disp">
            <div className="extra-detail">
              <div className="contain">
                <ul>
                  <li className="grid-flex">
                    <span>Email address</span>
                    <span>{userData.email}</span>
                  </li>
                  <li className="grid-flex">
                    <span>Phone number</span>
                    <span>{userData.phone_number}</span>
                  </li>
                  <li className="grid-flex">
                    <span>Referral code</span>
                    <span>{userData.ref_code}</span>
                  </li>
                  <li className="grid-flex">
                    <span>Transaction code</span>
                    <span>{userData.pin}</span>
                  </li>
                </ul>
                <button
                  onClick={toggleTransactionCodeDrawer}
                  className="btn btn-red"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Drawer>
        <Drawer
          footer={null}
          onClose={toggleSalesAgentMessage}
          open={displaySalesAgentMessage}
          placement="bottom"
        >
          <div className="modal-disp empty">
            <div className="extra-detail">
              <div className="contain">
                <img
                  src={EmptyGif}
                  alt="empty illustrations"
                  className="empty-illustration"
                />
                <p>
                  Join us as a Sales agent to generate your electronic identity
                  data (e-ID)
                </p>
                <Link
                  to={AppRoutes.partner}
                  style={{ display: "block", textAlign: "center" }}
                  className="btn btn-red"
                >
                  Join Us
                </Link>
              </div>
            </div>
          </div>
        </Drawer>
        <Drawer
          footer={null}
          onClose={() => setdisplayUpdateProfilePicModal(false)}
          open={displayUpdateProfilePicModal}
          placement="bottom"
        >
          <div>
            <div className="form-drawer-title">
              <div>
                <h3>Update Picture</h3>
              </div>
              <div>
                <div
                  //   onClick={() => setOpenBeneficiaryModal(false)}
                  className="cancel-bar"
                >
                  <ion-icon name="close-outline"></ion-icon>
                </div>
              </div>
            </div>
            <div className="contain mt_4">
              <div
                className=""
                style={{ width: "max-content", margin: "0px auto" }}
              >
                <Upload
                  name="avatar"
                  multiple={false}
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl?.length ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              <div className="mt_3">
                <div>
                  <button
                    onClick={UpdateProfilePicFunct}
                    className="btn btn-blue"
                  >
                    Update Profile Picture
                  </button>
                </div>
                {/* <div className="">
                  <button className="btn-cancel">Cancel</button>
                </div> */}
              </div>
            </div>
          </div>
        </Drawer>
        <FooterDisplay />
      </div>
    </Spin>
  );
};

export default ProfileSettings;
